import type {
  BrandResponse,
  CommentPageParams,
  CommentResponse,
  CommonType,
  DealerParams,
  DealerResponse,
  HelpAllResponse,
  HelpDetailResponse,
  IssueResponse,
  MiniProgramQrcodeParam,
  ModelResponse,
  PartResponse,
  PostCommentParams,
  PostCommentResponse,
  SeriesResponse,
} from './types'
import type { FetchResponseType, PaginationResponse } from '@/server/request/types'
import { useApiFetch } from '@/server/request'
import type { ISSUE_TYPE_CODE } from '@/shared/constants'

/**
 * help
 */
export async function getHelpAllApi() {
  return useApiFetch<FetchResponseType<HelpAllResponse[]>>('/webportal/base/help/webs', {
    method: 'GET',
  })
}
export async function getHelpDetailApi(helpId: number) {
  return useApiFetch<FetchResponseType<HelpDetailResponse>>('/webportal/base/help', {
    method: 'GET',
    params: { helpId },
  })
}

export async function getCommentListApi(type: CommonType = 'news', params: CommentPageParams) {
  const commonParams: Record<string, any> = {
    ...params,
  }
  const api: any = {
    news_api: '/webportal/base/article/commentPage',
    complaint_api: '/webportal/base/comment/page',
    collective_api: '/webportal/base/collective/commentPage',
  }

  switch (type) {
    case 'news':
      commonParams.articleId = params.id
      break
    case 'complaint':
      commonParams.complainId = params.id
      break
    case 'collective':
      commonParams.collectiveId = params.id
      break
  }
  delete commonParams.id

  const url = api[`${type}_api`]
  return useApiFetch<FetchResponseType<PaginationResponse<CommentResponse[]>>>(url, {
    method: 'GET',
    params: commonParams,
  })
}

export async function postCommentApi(type: CommonType = 'news', params: PostCommentParams) {
  const commonParams: Record<string, any> = {
    ...params,
  }
  const api: any = {
    news_api: '/webportal/article/comment',
    complaint_api: '/webportal/complain/comment/publish',
    collective_api: '/webportal/collective/comment/publish',
  }

  switch (type) {
    case 'news':
      commonParams.articleId = params.id
      break
  }

  const url = api[`${type}_api`]
  return useApiFetch<FetchResponseType<PostCommentResponse>>(url, {
    method: 'POST',
    params: commonParams,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export async function postCommentFavoriteApi(type: CommonType = 'news', params: { commentId: number, isGood: boolean }) {
  const api: any = {
    news_api: '/webportal/article/comment/good',
    complaint_api: '/webportal/complain/comment/good',
    collective_api: '/webportal/collective/comment/good',
  }
  const url = api[`${type}_api`]
  return useApiFetch<FetchResponseType<PostCommentResponse>>(url, {
    method: 'POST',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 内容点赞
 */
export async function postCommonFavoriteApi(
  type: CommonType = 'news',
  params: { id: number, isGood: boolean },
) {
  const api: any = {
    news_api: '/webportal/article/good',
    complaint_api: '/webportal/complain/good/',
    collective_api: '/webportal/collective/good/',
  }
  const commonParams: Record<string, any> = {}
  let url = api[`${type}_api`]
  if (type === 'news') {
    commonParams.articleId = params.id
    commonParams.isGood = params.isGood
  }
  else {
    url += params.id
    commonParams.isGood = params.isGood
  }

  return useApiFetch<FetchResponseType<boolean>>(url, {
    method: 'POST',
    params: commonParams,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 内容收藏
 */
export async function postCommonCollectApi(
  type: CommonType = 'news',
  params: { id: number, isCollect: boolean },
) {
  const api: any = {
    news_api: '/webportal/article/collection',
    complaint_api: '/webportal/complain/collect/', // {complainId}
  }
  let url = api[`${type}_api`]
  const commonParams: Record<string, any> = {}

  if (type === 'complaint') {
    url += `${params.id}`
    commonParams.isCollect = params.isCollect
  }
  else {
    commonParams.articleId = params.id
    commonParams.isGood = params.isCollect
  }

  return useApiFetch<FetchResponseType<boolean>>(url, {
    method: 'POST',
    params: commonParams,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export async function brandListApi(params: { brandName?: string } = {}) {
  return useApiFetch<FetchResponseType<Record<string, BrandResponse[]>>>('/webportal/base/complainPublish/brandLs', {
    method: 'GET',
    params,
  })
}

export async function seriesListApi(brandId: number | string) {
  return useApiFetch<FetchResponseType<SeriesResponse[]>>('/webportal/base/complainPublish/seriesLs', {
    method: 'GET',
    params: { brandId },
  })
}
export async function modelListApi(params: {
  seriesId: number
  year?: number
}) {
  return useApiFetch<FetchResponseType<Record<string, ModelResponse[]>>>('/webportal/base/complainPublish/modelLs', {
    method: 'GET',
    params,
  })
}

export async function getDealerListApi(params: DealerParams) {
  return useApiFetch<FetchResponseType<DealerResponse[]>>('/webportal/base/complainPublish/dealerLs', {
    method: 'GET',
    params,
  })
}

export async function issueListApi(params?: {
  issueTypeCode?: keyof typeof ISSUE_TYPE_CODE
}) {
  return useApiFetch<FetchResponseType<IssueResponse[]>>('/webportal/base/complainPublish/issueLs', {
    method: 'GET',
    params,
  })
}

export async function partsListApi(partsClasszIds: number | number[]) {
  return useApiFetch<FetchResponseType<Record<number, PartResponse[]>>>('/webportal/base/complainPublish/partsLs', {
    method: 'GET',
    params: { partsClasszIds },
  })
}

export async function getMiniProgramQrcode(params: MiniProgramQrcodeParam) {
  return useApiFetch<FetchResponseType<string>>('/thirdparty/base/wx/miniappQRCode', {
    method: 'POST',
    params,
  })
}
